<template>
    <div class='treatmentBreakdown' :style='treatmentBreakdown'>
        <common-nav-bar title='治疗明细'></common-nav-bar>
        <van-tabs class='m0' v-model='active' @change='changeTab'>
            <van-tab title='月统计'>
                <van-cell-group inset class='patientList m0'>
                    <van-cell
                        @click='showMonthPopup = true'
                        class='bgNo'
                        center
                        title-class='totalTitle'
                        value-class='registrationDate'
                        :value="'总计：' + patients.length + '人'"
                    >
                        <template #title>
                            <span>{{ formatMonth() }}</span>
                            <van-icon :name='iconDown' class='iconDown' />
                        </template>
                    </van-cell>

                    <van-cell
                        v-for='(item, i) in patients'
                        :key='i'
                        class='bgNo'
                        center
                        title-class='friendName'
                        value-class='registrationDate'
                        :title='item.name'
                        :value="'注册日期：' + formatDay(item.createdAt)"
                    >
                        <template #icon>
                            <van-image :src='item.wxHeadimgurl || userPhoto' width='48' />
                        </template>
                        <template #title>
                            <span>{{ item.name }}</span
                            >
                            <van-icon
                                :name='item.sex === 1 ? sexNan : sexNv'
                                class='sex-icon'
                            />
                            <van-icon :name='phone' class='phone-icon' />
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-tab>
            <van-tab title='年统计'>
                <van-cell-group inset class='patientList'>
                    <van-cell
                        class='bgNo'
                        center
                        title-class='totalTitle'
                        value-class='registrationDate'
                        :value="'总计：' + patients.length + '人'"
                        @click='showYearPopup = true'
                    >
                        <template #title>
                            <span>{{ formatYear() }}</span
                            >
                            <van-icon :name='iconDown' class='iconDown' />
                        </template>
                    </van-cell>

                    <van-cell
                        v-for='(item, i) in patients'
                        :key='i'
                        class='bgNo'
                        center
                        title-class='friendName'
                        value-class='registrationDate'
                        :title='item.name'
                        :value="'注册日期：' + formatDay(item.createdAt)"
                    >
                        <template #icon>
                            <van-image :src='item.wxHeadimgurl || userPhoto' width='48' />
                        </template>
                        <template #title>
                            <span>{{ item.name }}</span
                            >
                            <van-icon
                                :name='item.sex === 1 ? sexNan : sexNv'
                                class='sex-icon'
                            />
                            <van-icon :name='phone' class='phone-icon' />
                        </template>
                    </van-cell>
                </van-cell-group>
            </van-tab>
        </van-tabs>
        <van-popup v-model='showMonthPopup' position='bottom'>
            <van-datetime-picker
                v-model='currentDate'
                type='year-month'
                title='选择年月'
                :min-date='minDate'
                :max-date='maxDate'
                :formatter='formatter'
                @confirm='pickMonth'
                @cancel='showMonthPopup = false'
            />
        </van-popup>
        <van-popup v-model='showYearPopup' position='bottom'>
            <van-picker
                :default-index='2'
                title='选择年份'
                show-toolbar
                :columns='years'
                :min-date='minDate'
                :max-date='maxDate'
                @confirm='pickYear'
                @cancel='showYearPopup = false'
            />
        </van-popup>
    </div>
</template>

<script>
import { getHasDevicePatients } from '@/services/doctor';
import { toast, formatDate, safeNewDate } from '@/util';
import CommonNavBar from '@/components/common-nav-bar';

export default {
    name: 'index',
    components: { CommonNavBar },
    data() {
        return {
            years: [],
            showMonthPopup: false,
            showYearPopup: false,
            minDate: new Date(2020, 0, 1),
            maxDate: new Date(2099, 11, 31),
            currentDate: new Date(),
            selectedMonth: new Date(),
            selectedYear: new Date(),
            defaultYear: `${new Date().getFullYear()}`,
            active: 0,
            treatmentBreakdown: {
                backgroundImage: 'url(' + require('@/assets/images/myBgReverse.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%'
            },
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            phone: require('@/assets/images/phoneIcon.png'),
            iconRight: require('@/assets/images/iconRight.png'),
            iconDown: require('@/assets/images/iconDown.png'),
            patients: []
        };
    },
    created() {
        for (let i = 2020; i < 2051; i++) {
            this.years.push(`${i}`);
        }
        this.getHasDevicePatients();
    },
    methods: {
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }
            return val;
        },
        async changeTab() {
            await this.getHasDevicePatients();
        },
        async pickMonth(val) {
            this.selectedMonth = safeNewDate(`${val}`);
            this.showMonthPopup = false;
            await this.getHasDevicePatients();
        },
        formatMonth() {
            return formatDate(this.selectedMonth, 'yyyy年MM月');
        },
        async pickYear(val) {
            this.selectedYear = safeNewDate(`${val}-01-01`);
            this.showYearPopup = false;
            await this.getHasDevicePatients();
        },
        formatYear() {
            return formatDate(this.selectedYear, 'yyyy年');
        },
        async getHasDevicePatients() {
            this.patients = [];
            const year =
                this.active === 0
                    ? formatDate(this.selectedMonth, 'yyyy')
                    : formatDate(this.selectedYear, 'yyyy');
            const month = this.active === 0 ? formatDate(this.selectedMonth, 'MM') : '';
            const { code, message, data } = await getHasDevicePatients({ year, month });
            if (!code) {
                return toast(message);
            }
            this.patients = data;
        },

        formatDay(day) {
            if (!day) {
                return '';
            }
            return formatDate(day, 'yyyy-MM-dd');
        }
    }
};
</script>

<style scoped>
.treatmentBreakdown {
    padding: 10px;
    box-sizing: border-box;
}

.totalTitle {
    font-size: 14px;
    color: #282a2d;
}

.integral {
    font-size: 14px;
    color: #58ae9f;
}

.patientList .van-cell__left-icon, .patientList .van-image {
    font-size: 48px;
    height: auto;
    line-height: 100%;
    margin-right: 10px;
    border-radius: 5px;
    overflow: hidden;
}

.friendName {
    font-size: 12px;
    color: #282a2d;
    margin-block: 10px;
}

.registrationDate {
    font-size: 10px;
    color: #868686;
}

.sex-icon,
.phone-icon {
    font-size: 14px;
    margin-left: 5px;
}

.iconDown {
    font-size: 10px;
    margin-left: 5px;
}

.patientList .van-cell__title,
.patientList .van-cell__value {
    -webkit-box-flex: auto;
    -webkit-flex: auto;
    flex: auto;
}

.tplCopy {
    color: #4ab8ab;
    text-decoration: underline;
    display: inline-block;
}

.treatmentBreakdown .van-tabs__line {
    background-color: #4ab8ab;
    width: 60px;
}
</style>
